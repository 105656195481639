require('./src/styles/global.css');
const React = require('react');
const Layout = require('./src/components/layout').default;

exports.wrapPageElement = ({ element, props }) => {
    // props provide same data to Layout as Page element will get
    // including location, data, etc - you don't need to pass it
    return <Layout {...props}>{element}</Layout>;
};

const configureHotjar = (h, o, t, j, a, r) => {
    h.hj =
        h.hj ||
        function () {
            (h.hj.q = h.hj.q || []).push(arguments);
        };
    h._hjSettings = { hjid: 2380793, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
};

const configureZohoWidget = () => {
    const script = document.createElement('script');
    script.id = 'zsiqscript';
    script.type = 'text/javascript';
    script.defer = true;
    script.src = 'https://salesiq.zoho.in/widget';
    const existingScriptTag = document.getElementsByTagName('script')[0];
    existingScriptTag.parentNode.insertBefore(script, existingScriptTag);
};

const configureSecurePrivacy = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://app.secureprivacy.ai/script/628e0b906138b4ebcf3af775.js';
    document.getElementsByTagName('head')[0].appendChild(script);
};

const addNoIndexMeta = () => {
    const meta = document.createElement('meta');
    meta.name = 'robots';
    meta.content = 'noindex';
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(meta);
};

const configureGumlet = () => {
    const script = document.createElement('script');
    script.src = 'https://cdn.gumlet.com/gumlet.js/2.1/gumlet.min.js';
    script.async = 1;
    document.getElementsByTagName('head')[0].appendChild(script);
};

const configureGoogleOptimize = () => {
    const script = document.createElement('script');
    script.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-P9ZNM2F';
    script.async = 1;
    document.getElementsByTagName('head')[0].appendChild(script);
};

const storeUtmParams = () => {
    const params = new URLSearchParams(window.location.search);
    const utm_source = params.get('utm_source');
    const utm_medium = params.get('utm_medium');
    const utm_campaign = params.get('utm_campaign');
    const utm_term = params.get('utm_term');
    const utm_content = params.get('utm_content');
    if (utm_source) {
        localStorage.setItem('utm_source', utm_source);
    }
    if (utm_medium) {
        localStorage.setItem('utm_medium', utm_medium);
    }
    if (utm_campaign) {
        localStorage.setItem('utm_campaign', utm_campaign);
    }
    if (utm_term) {
        localStorage.setItem('utm_term', utm_term);
    }
    if (utm_content) {
        localStorage.setItem('utm_content', utm_content);
    }
};

const appendUtmToDemoButton = () => {
    storeUtmParams();
    const source = localStorage.getItem('utm_source') ?? '';
    const medium = localStorage.getItem('utm_medium') ?? '';
    const campaign = localStorage.getItem('utm_campaign') ?? '';
    const term = localStorage.getItem('utm_term') ?? '';
    const content = localStorage.getItem('utm_content') ?? '';
    let utmString = '';
    if (source || medium || campaign || term || content) {
        utmString = `?utm_source=${source}&utm_medium=${medium}&utm_campaign=${campaign}&utm_term=${term}&utm_content=${content}`;
    }
    if (document.getElementById('ew-demo')) {
        document.getElementById('ew-demo').onclick = () => {
            window.open(`https://calendly.com/engagewith-demo/demo${utmString}`, '_blank');
        };
    }
};

exports.onClientEntry = () => {
    window.onload = () => {
        window.GUMLET_CONFIG = {
            hosts: [
                {
                    current: 'assets-springworks.s3.amazonaws.com',
                    gumlet: 'sw-assets.gumlet.io',
                },
                {
                    current: 'springrecruit-assets.s3.amazonaws.com',
                    gumlet: 'srec-assets.gumlet.io',
                },
            ],
            lazy_load: true, //If you need to disable lazy load for any image, just add data-gmlazy="false" to the <img>. Ref: https://www.gumlet.com/docs/image/integration-guides/frontend-javascript#usage
        };
        configureGumlet();
        if (!window.location.href.includes('dev.springworks')) {
            configureSecurePrivacy();
        }

        if (window.location.pathname.startsWith('/engagewith')) {
            appendUtmToDemoButton();
        }

        if (window.location.pathname === '/trivia/slack/') {
            configureGoogleOptimize();
        }
        setTimeout(() => {
            let $zoho = $zoho || {};
            $zoho.salesiq = $zoho.salesiq || {
                widgetcode:
                    '5a3e21632ad6679cd9fc6dfbe7ea25e595a831c1b21dd314fe9d0aba58f750d0075ebc810d87ea2dc038407d6030b289',
                values: {},
                ready: function () {
                    window.$zoho = $zoho;
                },
            };
            configureZohoWidget();

            // Only track on production page.
            if (
                window.location.hostname.includes('.springworks.in') &&
                !window.location.href.includes('dev.springworks')
            ) {
                if (window.location.pathname === '/engagewith/') {
                    configureHotjar(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
                }
            }
            if (window.location.href.includes('dev.springworks')) addNoIndexMeta();
        }, 2000);
    };
};
