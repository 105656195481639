import React from 'react';
import './layout.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from 'react-helmet';

export default function Layout({ children }) {
    return (
        <div className='layout-1'>
            <Helmet>
                <script data-react-helmet='true' type='application/ld+json'>
                    {`{
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "Springworks - HR Softwares",
                        "url": "https://www.springworks.in/",
                        "logo": "https://www.springworks.in/static/sw-logo-04eefeacfa315729a58423b8fb9bfe70.svg",
                        "sameAs": [
                            "https://www.facebook.com/springrole",
                            "https://twitter.com/springroleinc/",
                            "https://www.linkedin.com/organization-guest/company/springrole/",
                            "https://www.youtube.com/channel/UC7jYeZWJHhsV_2266gVMsZw"
                        ]
                    }`}
                </script>
                <script type='application/ld+json' defer='defer'>
                    {`{"@context": "https://schema.org",
                        "@type": "LocalBusiness",
                        "name": "Springworks - HR Softwares",
                        "image": "https://www.springworks.in/static/sw-logo-04eefeacfa315729a58423b8fb9bfe70.svg",
                        "@id": "https://www.springworks.in/#site",
                        "url": "https://www.springworks.in/",
                        "telephone": "+1-323.507.8708",
                        "priceRange": "$$",
                        "address": {
                        "@type": "PostalAddress",
                        "streetAddress": "1447 2nd Street, Suite 200",
                        "addressLocality": "Santa Monica",
                        "addressRegion": "CA",
                        "postalCode": "9041",
                        "addressCountry": "US"
                        },
                        "geo": {
                        "@type": "GeoCoordinates",
                        "latitude": 34.0141375,
                        "longitude": -118.4955985
                        },
                        "openingHoursSpecification": {
                        "@type": "OpeningHoursSpecification",
                        "dayOfWeek": [
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday",
                            "Saturday",
                            "Sunday"
                        ],
                        "opens": "00:00",
                        "closes": "23:59"
                        },
                        "sameAs": [
                        "https://www.facebook.com/springrole/",
                        "https://twitter.com/springroleinc",
                        "https://www.instagram.com/lifeatspringworks/",
                        "https://www.youtube.com/channel/UC7jYeZWJHhsV_2266gVMsZw",
                        "https://www.linkedin.com/company/springrole",
                        "https://us.springverify.com/"
                        ]
                    }`}
                </script>
                <link rel='preconnect' href='https://fonts.googleapis.com' />
                <link rel='preconnect' href='https://fonts.gstatic.com' crossorigin />
                <link
                    href="'https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@300;400;500;600;700;800;900&family=Montserrat:wght@400;500;600;700&family=Open+Sans:wght@300;400;600;700&family=Roboto:wght@300;400;500;700;900&display=swap'"
                    rel='stylesheet'
                    rel='preload'
                />
            </Helmet>
            {children}
        </div>
    );
}
